import { ReactElement, ReactNode, useState } from "react";
import { Navbar } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./NavMenu.module.css"
import AdditionalOptionsMenu, { IOptions } from "./elements/AdditionalOptionsMenu/AdditionalOptionsMenu";
import LogInStatus from "./elements/LogInStatus/LogInStatus";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface INavMenu {
  title?: ReactElement<any, any>;
  options: IOptions[];
  triggerAction?: (action: string) => void;
  navigate?: (path: string) => void;
}

const NavMenu: React.FC<INavMenu> = (props) => {
  const isMobile = useSelector((state: RootState) => state.client.isMobile);
  const backgroundColor: string = "white";
  const color: string = "black";
  const iconSize: string = "24px";
  const iconClass: string = "bi bi-three-dots";

  const navigate = useNavigate();
  const [optionsVisible, setOptionsVisible] = useState(false);

  const handleAdditionalOptionsVisibilityChange = (visible: boolean) => {
    setOptionsVisible(visible);
  }

  const triggerAction = (action: string) => {
    if (props.triggerAction) {
      props.triggerAction(action);
    }
  }

  const navigateTo = (path: string) => {
    if (path.substring(0, 4) === "http") {
      window.location.href = path;
    } else {
      navigate(path);
      if (process.env.NODE_ENV === "test" && props.navigate) {
        props.navigate(path);
      }
    }
  };
  
  return (
    <Navbar className={styles.bar} style={{backgroundColor: backgroundColor}}>
      {!isMobile && <span className={styles.title}>{props.title}</span>}
      <div className={isMobile ? styles.logoMobile : styles.logoDesktop} onClick={() => navigate("/")}><b>Wilcz</b></div>
      <div className={styles.menu}>
        <LogInStatus className={styles.options} iconSize={iconSize} color={color} />
        {props.options.length > 0 && 
          <div>
            <div className={styles.options} data-testid="options" onClick={() => setOptionsVisible(!optionsVisible)}>
              <i className={iconClass} style={{fontSize: iconSize, color: color}}></i>
            </div>
            <AdditionalOptionsMenu 
              visible={optionsVisible}
              options={props.options}
              color={color}
              backgroundColor={backgroundColor}
              onVisible={handleAdditionalOptionsVisibilityChange}
              triggerAction={triggerAction}
              navigate={navigateTo} />
          </div>
        }
      </div>
    </Navbar>
  );
}

export { NavMenu };
