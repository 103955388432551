import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ILogInStatus {
    iconSize: string;
    color: string;
    className: string;
}

const LogInStatus: React.FC<ILogInStatus> = (props) => {
    const isLoggedIn = useSelector((state: RootState) => state.client.isLoggedIn)
    const currentUrl = window.location.href;
    const navigate = useNavigate();

    return (
        <div className={props.className} onClick={() => navigate(`/accounts?continue=${encodeURIComponent(currentUrl)}`)}>
            { isLoggedIn ?
                <i className="bi bi-person-check" style={{fontSize: props.iconSize, color: props.color}}></i>
                :
                <i className="bi bi-person" style={{fontSize: props.iconSize, color: props.color}}></i>
            }
        </div> 
    )
}

export default LogInStatus;