import { ReactElement } from "react";
import styles from "./AdditionalOptionsMenu.module.css";

export interface IOptions {
    link?: string;
    action?: string;
    name: string;
    icon?: ReactElement<any, any>;
}

interface IAdditionalOptionsMenu {
    options: IOptions[];
    visible: boolean;
    color: string;
    backgroundColor: string;
    triggerAction?: (action: string) => void;
    onVisible: (flag: boolean) => void;
    navigate: (path: string) => void;
}

const AdditionalOptionsMenu: React.FC<IAdditionalOptionsMenu> = (props) => {
    let height = 14 + (props.options.length * 31) + "px";
    
    return (
        <div className={styles.options} id="additional-options" data-testid="additional-options" style={{visibility: props.visible ? "visible" : "hidden", backgroundColor: props.backgroundColor, height: height}} >
            <div className={styles.optionsMenu}>{
                props.options.map((option, index) =>
                    <div key={"options-element-" + index} className={styles.element} onClick={() => {
                        props.onVisible(false);
                        {option.link &&
                            props.navigate(option.link);
                        }
                        {option.action && props.triggerAction &&
                            props.triggerAction(option.action);
                        }
                    }}>
                        <span style={{color: props.color}}>
                            <span>{option.icon}</span>
                            <span className={styles.name}>{option.name}</span>
                        </span>
                    </div>
                )
            }
            </div>
        </div>
    )
}

export default AdditionalOptionsMenu;
