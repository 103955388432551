import { useDispatch } from "react-redux";
import { setUrlProtocol } from "../../../redux/actions/configActions";

function SetHardcodedValues() {
  const dispatch = useDispatch();
  dispatch(setUrlProtocol("https"));
  return null;
}

export default SetHardcodedValues;
