import { useEffect, useState } from "react";
import { NavMenu } from "../../components/feature/NavMenu/NavMenu";
import { useSelector } from "react-redux";
import setThemeColor from "../../utils/setThemeColor/setThemeColor";
import { RootState } from "../../redux/store";
import styles from "./WelcomePage.module.css";
import { useTranslation } from "react-i18next";

const WelcomePage: React.FC = () => {
  const { t } = useTranslation();
  const recipinsLogo = require("./elements/logo.png");
  const recipesUrl =
    useSelector((state: RootState) => state.config.urlProtocol) +
    "://recipins.wilcz.com";
  let options = [
    {
      link: "/settings",
      name: t("containers.settingsPage.title"),
      icon: <i className="bi bi-gear"></i>,
    },
    {
      link: "/info",
      name: t("containers.infoPage.title"),
      icon: <i className="bi bi-info-circle"></i>,
    },
  ];
  var WelocmePageNavMenu = (
    <NavMenu
      title={
        <span>
          <i className="bi bi-house"></i> {t("containers.welcomePage.title")}
        </span>
      }
      options={options}
    />
  );
  // iPhone theme-color set
  useEffect(() => {
    setThemeColor("#ffffff");
  }, []);

  if (useSelector((state: RootState) => state.client.isMobile)) {
    return (
      <div className={styles.background}>
        {WelocmePageNavMenu}
        <div className={styles.centerMobile}>
          <h5 className={styles.welcome}>
            {t("containers.welcomePage.welcome")}
          </h5>
          <div className={styles.containerThankYou}>
            <p className={styles.blackNoMargin}>
              {t("containers.welcomePage.tankYouText")}
            </p>
            <p className={styles.blackNoMargin}>
              {t("containers.welcomePage.feelFreeText")}
            </p>
          </div>
          <div className={styles.containerLinks}>
            <div>
              <a href="https://github.com/WilczCom">
                <i className={"bi bi-github " + `${styles.link}`}></i>
              </a>
              <a href="https://www.linkedin.com/company/wilcz/">
                <i className={"bi bi-linkedin " + `${styles.link}`}></i>
              </a>
              <a href="https://www.instagram.com/wilczcom/">
                <i className={"bi bi-instagram " + `${styles.link}`}></i>
              </a>
            </div>
            <div
              className={styles.recipinsLink}
              onClick={() => {
                window.location.href = recipesUrl;
              }}
            >
              <img className={styles.recipinsLogo} src={recipinsLogo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.background}>
      {WelocmePageNavMenu}
      <div className={styles.centerDesktop}>
        <p className={styles.welcome}>{t("containers.welcomePage.welcome")}</p>
        <p className={styles.black}>
          {t("containers.welcomePage.tankYouText")}{" "}
          {t("containers.welcomePage.feelFreeText")}
        </p>
        <div className={styles.containerLinks}>
          <div>
            <a href="https://github.com/WilczCom">
              <i className={"bi bi-github " + `${styles.link}`}></i>
            </a>
            <a href="https://www.linkedin.com/company/wilcz/">
              <i className={"bi bi-linkedin " + `${styles.link}`}></i>
            </a>
            <a href="https://www.instagram.com/wilczcom/">
              <i className={"bi bi-instagram " + `${styles.link}`}></i>
            </a>
          </div>
          <div
            className={styles.recipinsLink}
            onClick={() => {
              window.location.href = recipesUrl;
            }}
          >
            <img className={styles.recipinsLogo} src={recipinsLogo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
