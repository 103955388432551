import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/common/Layout/Layout";
import "./custom.css";
import { Provider, useDispatch } from "react-redux";
import store from "./redux/store";
import SetStartupValues from "./utils/SetStartupValues/SetStartupValues";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/translation/i18n";

const renderRoutes = (routes) => {
  return routes.map((route, index) => {
    const { element, children, ...rest } = route;
    return (
      <Route key={index} {...rest} element={element}>
        {children && renderRoutes(children)}
      </Route>
    );
  });
};

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <SetStartupValues />
        <Layout>
          <Routes>{renderRoutes(AppRoutes)}</Routes>
        </Layout>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
