import styles from "./LogOut.module.css";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { setIsLoggedIn } from "../../../redux/actions/clientActions";
import { Api } from "../../../services/Api";
import { useTranslation } from "react-i18next";
import { setToast } from "../../../redux/actions/appActions";
import { useNavigate } from "react-router-dom";

interface ILogOut {
  continue: string | null;
}

const LogOut: React.FC<ILogOut> = (props) => {
  const { t } = useTranslation();
  const isMobile = useSelector((state: RootState) => state.client.isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    Api.post("logout").then(
      (res) => {
        if (res.ok) {
          dispatch(setIsLoggedIn(false));
          dispatch(
            setToast({ text: "User logged out correctly", isPositive: true })
          );
          if (props.continue) {
            window.location.href = props.continue;
          } else {
            navigate("/");
          }
        } else {
          dispatch(
            setToast({ text: "Not logged out correctly", isPositive: false })
          );
        }
      },
      (error) => {
        dispatch(setToast({ text: error.message, isPositive: false }));
      }
    );
  };

  return (
    <div className="container pt-3">
      <Row>
        <div className={isMobile ? styles.centerMobile : styles.centerDesktop}>
          <h2 className="text-center">
            {t("components.feature.logOut.title")}
          </h2>
          <Row>
            <Col>
              <div className="my-3">
                <Button variant="dark" className="w-100" onClick={handleLogOut}>
                  {t("components.feature.logOut.button")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default LogOut;
