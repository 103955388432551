import React, { useEffect } from "react";
import setThemeColor from "../../utils/setThemeColor/setThemeColor";
import { NavMenu } from "../../components/feature/NavMenu/NavMenu";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import styles from "./SettingsPage.module.css"
import ChangeUiLanguage from "../../components/feature/ChangeUiLanguage/ChangeUiLanguage";

const SettingsPage: React.FC = () => {
    const isMobile = useSelector((state: RootState) => state.client.isMobile);
    const { t } = useTranslation();
    const options = ([
        {
            "link": "/",
            "name": t("containers.welcomePage.title"),
            "icon": <i className="bi bi-house"></i>
        },
        {
            "link": "/info",
            "name": t("containers.infoPage.title"),
            "icon": <i className="bi bi-info-circle"></i>
        }
    ]);
    
    useEffect(() => {
        setThemeColor("#ffffff");
    }, []);

    return (
        <div>
            <NavMenu options={options} title={<span><i className="bi bi-gear"></i> {t("containers.settingsPage.title")}</span>} />
            {isMobile && <p className={styles.title}><i className="bi bi-gear"></i> {t("containers.settingsPage.title")}</p>}
            <div className={isMobile ? styles.containerMobile : styles.containerDesktop}>
                <ChangeUiLanguage />
            </div>
        </div>
    )
}

export default SettingsPage;
