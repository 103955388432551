import { SET_IS_WAITING, SET_TOAST } from "../actionTypes/appActionTypes";
import { IConfigAction } from "../interfaces/ConfigInterfaces";

export const setIsWaiting = (value: boolean): IConfigAction => ({
    type: SET_IS_WAITING,
    payload: value, 
});

export const setToast = (value: {text: string, isPositive: boolean}): IConfigAction => ({
    type: SET_TOAST,
    payload: value, 
});
