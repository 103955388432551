
import { SET_IS_LOGGED_IN, SET_IS_MOBILE } from "../actionTypes/clientActionTypes";
import { IClientAction } from "../interfaces/ClientInterfaces";

export const setIsMobile = (isMobile: boolean): IClientAction => ({
  type: SET_IS_MOBILE,
  payload: isMobile, 
});

export const setIsLoggedIn = (isLoggedIn: boolean): IClientAction => ({
  type: SET_IS_LOGGED_IN,
  payload: isLoggedIn,
});