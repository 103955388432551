import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import styles from "./RefreshLogin.module.css";
import { Api } from "../../../services/Api";
import { useTranslation } from "react-i18next";

interface IRefreshLogin {
  continue: string | null;
}

const RefreshLogin: React.FC<IRefreshLogin> = (props) => {
  const { t } = useTranslation();
  const isMobile = useSelector((state: RootState) => state.client.isMobile);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  async function checkIsLogged() {
    var response = await Api.get("islogged");
    setIsLoggedIn(response.ok);
  }

  useEffect(() => {
    if (isLoggedIn && props.continue !== null) {
      window.location.href = props.continue;
    }
  }, [isLoggedIn]);

  useEffect(() => {
    checkIsLogged();
  }, []);

  return (
    <div className={isMobile ? styles.centerMobile : styles.centerDesktop}>
      <h6 className="text-center">
        {t("components.feature.refreshLogin.text")}
      </h6>
    </div>
  );
};

export default RefreshLogin;
