import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Translation from './i18n.types';
import getUiLanguage from "../getUiLanguage/getUiLanguage";

import translationEN from "./languages/en/translation.json";
import translationPL from "./languages/pl/translation.json";

const resources: Record<string, Translation> = {
  en: translationEN as Translation,
  pl: translationPL as Translation,
};

i18n
  .use(initReactI18next)
  .init({
    resources: { ...resources },
    lng: getUiLanguage(),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
