import { Reducer } from "redux";
import { SET_TOAST, SET_IS_WAITING } from "../actionTypes/appActionTypes";
import { IAppAction, IAppStates } from "../interfaces/AppInterfaces";

const initialState: IAppStates = {
  isWaiting: false,
  toast: {text: "", isPositive: false}
};

const appReducer: Reducer<IAppStates, IAppAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_IS_WAITING:
      return {
        ...state,
        isWaiting: action.payload,
      };
    case SET_TOAST:
      return {
        ...state,
        toast: action.payload
      }
    default:
      return state;
  }
};

export default appReducer;
