import SetConfigValues from "./elements/SetConfigValues";
import SetDynamicValues from "./elements/SetDynamicValues";
import SetHardcodedValues from "./elements/SetHardcodedValues";

function SetStartupValues() {
    return (
        <div>
            <SetHardcodedValues />
            <SetConfigValues />
            <SetDynamicValues />
        </div>
    )
}

export default SetStartupValues;
