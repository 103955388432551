import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import styles from "./VerifyEmailPage.module.css";
import { setToast } from "../../redux/actions/appActions";

interface IVerifyEmailPage {}

const VerifyEmailPage: React.FC<IVerifyEmailPage> = (props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isMobile = useSelector((state: RootState) => state.client.isMobile);
  const [status, setStatus] = useState(
    t("containers.verifyEmailPage.inProgress")
  );
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const bodyJson = {
      Token: token,
    };
    fetch("api/signup/verify-email", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyJson),
    }).then(
      (response) => {
        if (response.ok) {
          setStatus(t("containers.verifyEmailPage.success"));
          dispatch(
            setToast({ text: "Email verified correctly", isPositive: true })
          );
          navigate("/accounts");
        } else {
          setStatus(t("containers.verifyEmailPage.fail"));
          dispatch(
            setToast({ text: "Email varification failed", isPositive: false })
          );
        }
      },
      (error) => {
        dispatch(setToast({ text: error.message, isPositive: false }));
      }
    );
  }, [token]);

  return (
    <div className="container pt-3">
      <Row>
        <div className={isMobile ? styles.centerMobile : styles.centerDesktop}>
          <h2 className="text-center">
            {t("containers.verifyEmailPage.title")}
          </h2>
          <Row>
            <Col>
              <div className="my-3 d-flex justify-content-center">{status}</div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default VerifyEmailPage;
