import { Container } from "reactstrap";
import NotificationToast from "../../feature/NotificationToast/NotificationToast";

const Layout: React.FC<any> = (props) => {
  return (
    <div>
      <Container tag="main">
        {props.children}
      </Container>
      <NotificationToast />
    </div>
  );
}

export { Layout };
