
import Cookies from "js-cookie";

const getUiLanguage: () => string = () => {
    var languageCode = Cookies.get("uiLanguageCode");
    if (languageCode !== undefined) {
        return languageCode;
    }
    return "en";
};

export default getUiLanguage;
