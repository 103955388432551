import React, { useEffect, useState } from "react";
import { Form, Button, FloatingLabel } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./SignUp.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Select from "react-select";
import {
  selectStyles,
  selectTheme,
} from "../../../utils/selectUtils/selectUtils";
import { useTranslation } from "react-i18next";
import { setToast } from "../../../redux/actions/appActions";
import { useNavigate } from "react-router-dom";

interface ISignUp {
  logInSelectedTrigger(): void;
}

const SignUp: React.FC<ISignUp> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "",
    label: t("components.feature.signUp.uiLanguage"),
  });
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(true);
  const [languages, setLangauges] = useState<Array<any>>();
  const isMobile = useSelector((state: RootState) => state.client.isMobile);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [uiLanguageError, setUiLanguageError] = useState("");
  const [selectStyle, setSelectStyle] = useState({});
  const navigate = useNavigate();

  const handleSelectChange = (event: any) => {
    const { label, value } = event;
    setSelectedLanguage({ value, label });
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
  };

  const handleUsernameChange = (value: string) => {
    setUsername(value);
  };

  const handleSignUp = (event: React.FormEvent) => {
    event.preventDefault();

    if (
      !isUsernameValid() ||
      !isEmailValid() ||
      !isPasswordValid() ||
      !isConfirmPasswordValid() ||
      !isSelectedLanguageValid()
    ) {
      dispatch(
        setToast({ text: "One or more validation errors", isPositive: false })
      );
      return;
    }

    const bodyJson = {
      Username: username,
      Email: email,
      Password: password,
      LanguageCode: selectedLanguage.value,
    };

    fetch("api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyJson),
    }).then(
      (response) => {
        if (response.ok) {
          dispatch(
            setToast({ text: "User signed up correctly", isPositive: true })
          );
          props.logInSelectedTrigger();
          navigate("sign-up-success");
        } else {
          dispatch(
            setToast({
              text: "User not signed up correctly",
              isPositive: false,
            })
          );
          setUsernameError("That username or email is already in use");
          setEmailError("That username or email is already in use");
        }
      },
      (error) => {
        dispatch(setToast({ text: error.message, isPositive: false }));
      }
    );
  };

  const isUsernameValid = () => {
    const whitespaceRegex = /\s/;
    const specialCharRegex = /^[a-zA-Z0-9_.]*$/;

    if (username.trim().length === 0) {
      setUsernameError("Username must have at least one character");
    } else if (whitespaceRegex.test(username)) {
      setUsernameError("Username should not contain any whitespace");
    } else if (!specialCharRegex.test(username)) {
      setUsernameError("Username should not contain special characters");
    } else {
      setUsernameError("");
      return true;
    }
    return false;
  };

  const isPasswordValid = () => {
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters");
    } else if (!/\d/.test(password)) {
      setPasswordError("Password must contain at least one number");
    } else if (!/[A-Z]/.test(password)) {
      setPasswordError("Password must contain at least one uppercase letter");
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setPasswordError("Password must contain at least one special character");
    } else {
      setPasswordError("");
      return true;
    }
    return false;
  };

  const isConfirmPasswordValid = () => {
    if (confirmPassword === "") {
      setConfirmPasswordError("Passwords do not match");
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
      return true;
    }
    return false;
  };

  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
      return true;
    }
    return false;
  };

  const isSelectedLanguageValid = () => {
    if (selectedLanguage.value === "") {
      setUiLanguageError("UI language must be selected");
    } else {
      setUiLanguageError("");
      return true;
    }
    return false;
  };

  useEffect(() => {
    setSelectStyle(
      uiLanguageError
        ? {
            control: (provided: any) => ({
              ...provided,
              borderColor: "red",
              minHeight: 58,
              borderRadius: "6px",
            }),
          }
        : {
            control: (provided: any) => ({
              ...provided,
              minHeight: 58,
              borderRadius: "6px",
            }),
          }
    );
  }, [uiLanguageError]);

  useEffect(() => {
    isPasswordValid();
  }, [password]);

  useEffect(() => {
    isConfirmPasswordValid();
  }, [password, confirmPassword]);

  useEffect(() => {
    isSelectedLanguageValid();
  }, [selectedLanguage]);

  useEffect(() => {
    isUsernameValid();
  }, [username]);

  useEffect(() => {
    isEmailValid();
  }, [email]);

  useEffect(() => {
    fetch("api/languages/ui", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.log("ERROR: can not download languages");
        }
        return response.json();
      })
      .then((result) => {
        const mappedLanguages = result.map((language: any) => ({
          value: language.code,
          label: language.name,
        }));
        setLangauges(mappedLanguages);
        setIsLoadingLanguages(false);
      });

    setPasswordError("");
    setUsernameError("");
    setEmailError("");
    setConfirmPasswordError("");
    setUiLanguageError("");
  }, []);

  return (
    <div className="container pt-3">
      <Row>
        <div className={isMobile ? styles.centerMobile : styles.centerDesktop}>
          <h2 className="text-center">
            {t("components.feature.signUp.title")}
          </h2>
          <Row>
            <Form.Group controlId="formUsername" className="my-3">
              <FloatingLabel label={t("components.feature.signUp.username")}>
                <Form.Control
                  type="text"
                  value={username}
                  onChange={(e) => handleUsernameChange(e.target.value)}
                  placeholder={t("components.feature.signUp.username")}
                  isInvalid={!!usernameError}
                  style={{ borderColor: usernameError ? "red" : "" }}
                />
              </FloatingLabel>
              <Form.Text style={{ color: "red" }}>{usernameError}</Form.Text>
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <FloatingLabel label={t("components.feature.signUp.email")}>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => handleEmailChange(e.target.value)}
                  placeholder={t("components.feature.signUp.email")}
                  isInvalid={!!emailError}
                  style={{ borderColor: emailError ? "red" : "" }}
                />
              </FloatingLabel>
              <Form.Text style={{ color: "red" }}>{emailError}</Form.Text>
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <FloatingLabel label={t("components.feature.signUp.password")}>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  placeholder={t("components.feature.signUp.password")}
                  isInvalid={!!passwordError}
                  style={{ borderColor: passwordError ? "red" : "" }}
                />
              </FloatingLabel>
              <Form.Text style={{ color: "red" }}>{passwordError}</Form.Text>
            </Form.Group>

            <Form.Group controlId="formConfirmPassword" className="mb-3">
              <FloatingLabel
                label={t("components.feature.signUp.confirmPassword")}
              >
                <Form.Control
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                  isInvalid={!!confirmPasswordError}
                  placeholder={t("components.feature.signUp.confirmPassword")}
                  style={{ borderColor: confirmPasswordError ? "red" : "" }}
                />
              </FloatingLabel>
              <Form.Text style={{ color: "red" }}>
                {confirmPasswordError}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="languageCode" className="mb-3">
              <Select
                className={styles.langaugeForm}
                isSearchable={true}
                name="color"
                isLoading={isLoadingLanguages}
                value={selectedLanguage}
                options={languages}
                onChange={handleSelectChange}
                styles={{ ...selectStyles, ...selectStyle }}
                theme={selectTheme}
                placeholder={t("components.feature.signUp.uiLanguage")}
              />
              <Form.Text style={{ color: "red" }}>{uiLanguageError}</Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <div className="my-3">
                <Button
                  variant="dark"
                  className="w-100"
                  type="button"
                  onClick={handleSignUp}
                >
                  {t("components.feature.signUp.button")}{" "}
                  <i className="bi bi-arrow-right"></i>
                </Button>
                <Button
                  variant="light"
                  onClick={() => props.logInSelectedTrigger()}
                  className="w-100 mt-2"
                >
                  <i className="bi bi-arrow-left"></i>{" "}
                  {t("components.feature.logIn.button")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default SignUp;
