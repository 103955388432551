import { Reducer } from "redux";
import { SET_IS_LOGGED_IN, SET_IS_MOBILE } from "../actionTypes/clientActionTypes";
import { IClientAction, IClientStates } from "../interfaces/ClientInterfaces";

const initialState: IClientStates = {
  isMobile: false,
  isLoggedIn: false,
};

const clientReducer: Reducer<IClientStates, IClientAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return state;
  }
};

export default clientReducer;
