import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ChangeLanguageBar: React.FC = () => {
    const [languages, setLangauges] = useState<Array<any>>();
    const { i18n } = useTranslation();

    const changeLanguage = (languageCode: string) => {
        i18n.changeLanguage(languageCode);
        const bodyJson = {
            LanguageCode: languageCode,
        }
        fetch("api/ui/language-cookie", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyJson)
        }).then(response => {
            if (response.ok) {
                console.log("Langauge changed");
            }
        })
    }

    useEffect(() => {
        fetch("api/languages/ui", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(response => {
            if(!response.ok) {
              console.log("ERROR: can not download langauges");
            }
            return response.json();
          })
          .then(
            (result) => {
                const mappedLanguages = result.map((language: any) => ({
                  value: language.code,
                  label: language.name,
                }));
                setLangauges(mappedLanguages);
            }
        )
    }, []);

    return (
        <Container className="d-flex align-items-center justify-content-center">
            <Row>
                {languages?.map((option, index) => (
                    <Col key={index} xs="auto" className="mx-2">
                        <Button variant="link" size="sm" onClick={() => changeLanguage(option.value)}>
                            {option.label}
                        </Button>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default ChangeLanguageBar;