import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./SignUpSuccess.module.css";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";

const SignUpSuccess: React.FC = () => {
  const isMobile = useSelector((state: RootState) => state.client.isMobile);
  const { t } = useTranslation();

  return (
    <div className="container pt-3">
      <div className={isMobile ? styles.centerMobile : styles.centerDesktop}>
        <h2 className="text-center">{t("containers.SignUpSuccess.title")}</h2>
        <Row className="pt-3 d-flex justify-content-center">
          {t("containers.SignUpSuccess.text")}
        </Row>
        <Row className="pt-3">
          <ol>
            <li>{t("containers.SignUpSuccess.step1")}</li>
            <li>
              <b>{t("containers.SignUpSuccess.step2")}</b>
            </li>
            <li>{t("containers.SignUpSuccess.step3")}</li>
          </ol>
        </Row>
        <Row>{t("containers.SignUpSuccess.final")}</Row>
        <Row className="pt-3">{t("containers.SignUpSuccess.thankYou")}</Row>
      </div>
    </div>
  );
};

export default SignUpSuccess;
