import { useDispatch } from "react-redux";
import {
  setIsLoggedIn,
  setIsMobile,
} from "../../../redux/actions/clientActions";
import isDeviceTypeMobile from "../../isDeviceTypeMobile/isDeviceTypeMobile";
import checkIsLoggedIn from "../../checkIsLogedIn/checkIsLoggedIn";
import { useEffect } from "react";

function SetDynamicValues() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsMobile(isDeviceTypeMobile()));
    dispatch(setIsLoggedIn(checkIsLoggedIn()));
  }, []);

  return null;
}

export default SetDynamicValues;
