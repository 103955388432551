export class Api {
    static get(endpoint: string): Promise<any> {
        return this.request(endpoint, "GET")
    }

    static put(endpoint: string, body?: any): Promise<any> {
        return this.request(endpoint, "PUT", body)
    }

    static post(endpoint: string, body?: any): Promise<any> {
        return this.request(endpoint, "POST", body)
    }
    
    private static async request(endpoint: string, method: string, body?: any): Promise<any> {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
            });
    
            return this.handleResponse(response);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

    private static async handleResponse(response: Response) {
        if (response.ok) {
        }
    
        if (response.status === 401) {
            this.renewAccessToken();
        }
    
        return response;
    }

    private static async renewAccessToken() {
        try {
            const response = await fetch("api/refreshaccesstoken", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            });
    
            if (response.ok) {
                window.location.reload();
            } else {
                const currentUrl = window.location.href;
                const domain = currentUrl.substring(0, currentUrl.indexOf('/', 8));
                window.location.href = `${domain}/accounts?continue=${encodeURIComponent(currentUrl)}`;
            }
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
}
