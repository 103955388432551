import { useEffect, useState } from "react";
import LogIn from "../../components/feature/LogIn/LogIn";
import setThemeColor from "../../utils/setThemeColor/setThemeColor";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LogOut from "../../components/feature/LogOut/LogOut";
import SignUp from "../../components/feature/SignUp/SignUp";
import styles from "./AccountsPage.module.css";
import { useLocation } from "react-router-dom";
import stringOrNullToBoolean from "../../utils/stringOrNullToBoolean/stringOrNullToBoolean";
import RefreshLogin from "../../components/feature/RefreshLogin/RefreshLogin";
import ChangeLanguageBar from "../../components/feature/ChangeLanguageBar/ChangeLanguageBar";

const AccountsPage: React.FC = () => {
  const isLoggedIn = useSelector((state: RootState) => state.client.isLoggedIn);
  const [isSignUpSelected, setIsSignUpSelected] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [continueUrl] = useState(urlParams.get("continue"));
  const [isRefresh] = useState(stringOrNullToBoolean(urlParams.get("refresh")));

  const logInSelectedTrigger = () => {
    setIsSignUpSelected(false);
  };

  const signUpSelectedTrigger = () => {
    setIsSignUpSelected(true);
  };

  useEffect(() => {
    setThemeColor("#ffffff");
  }, []);

  return (
    <div>
      <div className={styles.container}>
        {isRefresh ? (
          <RefreshLogin continue={continueUrl} />
        ) : isLoggedIn ? (
          <LogOut continue={continueUrl} />
        ) : (
          <div>
            {isSignUpSelected ? (
              <SignUp logInSelectedTrigger={logInSelectedTrigger} />
            ) : (
              <LogIn
                signUpSelectedTrigger={signUpSelectedTrigger}
                continue={continueUrl}
              />
            )}
            <ChangeLanguageBar />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountsPage;
