import { CSSObjectWithLabel, StylesConfig, Theme } from 'react-select';

export const selectStyles: StylesConfig<any> = {
  control: (provided) => ({
    ...provided,
    minHeight: 58,
    borderRadius: "6px"
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: "6px"
  }),
};

export const selectTheme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: "#212529",
  },
});
