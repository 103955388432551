const stringOrNullToBoolean = (value: string | null | undefined): boolean => {
    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    } else {
      return false;
    }
}

export default stringOrNullToBoolean;