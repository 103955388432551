import { Reducer } from "redux";
import { SET_URL_PROTOCOL } from "../actionTypes/configActionTypes";
import { IConfigAction, IConfigStates } from "../interfaces/ConfigInterfaces";

const initialState: IConfigStates = {
  urlProtocol: "https",
};

const configReducer: Reducer<IConfigStates, IConfigAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_URL_PROTOCOL:
      return {
        ...state,
        urlProtocol: action.payload,
      };
    default:
      return state;
  }
};

export default configReducer;
