import { useEffect } from "react";
import styles from "./InfoPage.module.css"
import setThemeColor from "../../utils/setThemeColor/setThemeColor";
import { useTranslation } from "react-i18next";
import { NavMenu } from "../../components/feature/NavMenu/NavMenu";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const MorePage: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useSelector((state: RootState) => state.client.isMobile);
    let options = [
        {
            "link": "/",
            "name": t("containers.welcomePage.title"),
            "icon": <i className="bi bi-house"></i>
        },
        {
            "link": "/settings",
            "name": t("containers.settingsPage.title"),
            "icon": <i className="bi bi-gear"></i>
        }
    ]

    useEffect(() => {
        setThemeColor("#ffffff");
    }, [])

    return (
        <div>
            <NavMenu title={<span><i className="bi bi-info-circle"></i> {t("containers.infoPage.title")}</span>} options={options} />
            {isMobile && <p className={styles.title}><i className="bi bi-info-circle"></i> {t("containers.infoPage.title")}</p>}
            <div className={useSelector((state: RootState) => state.client.isMobile) ? styles.containerMobile : styles.containerDesktop}>
                <h5 className="my-3">{t("containers.infoPage.legal")}</h5>
                <p>Copyright © 2024 Tomasz Wilczyński. All Rights Reserved.</p>
                <h5 className="my-3">{t("containers.infoPage.contact")}</h5>
                <p>contact@wilcz.com</p>
            </div>
        </div>
    )
}

export default MorePage;
