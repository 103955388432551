import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { IConfigStates } from "./interfaces/ConfigInterfaces";
import configReducer from "./reducers/configReducer";
import clientReducer from "./reducers/clientReducer";
import { IClientStates } from "./interfaces/ClientInterfaces";
import appReducer from "./reducers/appReducer";
import { IAppStates } from "./interfaces/AppInterfaces";

export interface RootState {
    app: IAppStates,
    client: IClientStates,
    config: IConfigStates,
}

const rootReducer = combineReducers<RootState>({
    app: appReducer,
    client: clientReducer,
    config: configReducer,
});

const store = configureStore({
    reducer: rootReducer,
});

export default store;
