import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import Select from "react-select";
import styles from "./ChangeUiLanguage.module.css";
import { Api } from "../../../services/Api";
import { RootState } from "../../../redux/store";
import { selectStyles, selectTheme } from "../../../utils/selectUtils/selectUtils";
import { setToast } from "../../../redux/actions/appActions";

const ChangeUiLanguage: React.FC = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state: RootState) => state.client.isLoggedIn);
    const [isLoadingLanguages, setIsLoadingLanguages] = useState(true);
    const [languages, setLangauges] = useState<Array<{value: string, label: string}>>();
    const [selectedUiLanguage, setSelectedUiLanguage] = useState({value: "", label: ""});
    const { i18n, t } = useTranslation();

    const handleUiLanguageChange = (event: any) => {
        const { label, value } = event;
        setSelectedUiLanguage({ value, label });
        const bodyJson = {
            LanguageCode: value,
        }
        if (isLoggedIn) {
            Api.put("ui/language", bodyJson)
                .then(response => {
                    if (response.ok) {
                        dispatch(setToast({text: "UI langauge changed", isPositive: true}));
                    } else {
                        dispatch(setToast({text: "UI langauge not changed", isPositive: false}));
                    }
                });
        } else {
            fetch("api/ui/language-cookie", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(bodyJson)
            }).then(response => {
                if (response.ok) {
                    dispatch(setToast({text: "UI langauge changed", isPositive: true}));
                } else {
                    dispatch(setToast({text: "UI langauge not changed", isPositive: false}));
                }
            });
        }

        i18n.changeLanguage(value);
    };

    useEffect(() => {
        fetch("api/languages/ui", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(response => {
            if(!response.ok) {
              console.log("ERROR: can not download langauges");
            }
            return response.json();
          })
          .then(
            (result) => {
                const mappedLanguages = result.map((language: any) => ({
                  value: language.code,
                  label: language.name,
                }));
                setLangauges(mappedLanguages);
                setSelectedUiLanguage(mappedLanguages.find((language: {value: string, label: string}) => language.value === i18n.language));
                setIsLoadingLanguages(false);
            }
        )
    }, []);

    return (
        <div>
            <h5 className="my-3">{t("containers.settingsPage.uiLanguage")}</h5>
            <Form.Group controlId="languageCode" className="mb-3">
                <Select
                    className={styles.langaugeForm}
                    isSearchable={true}
                    isLoading={isLoadingLanguages}
                    value={selectedUiLanguage}
                    options={languages}
                    onChange={handleUiLanguageChange}
                    styles={selectStyles}
                    theme={selectTheme}               
                />
            </Form.Group>
        </div>
    );
}

export default ChangeUiLanguage;
