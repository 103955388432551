import { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const NotificationToast: React.FC = () => {
    const [visible, setVisible] = useState(false);
    var toast = useSelector((state: RootState) => state.app.toast);

    useEffect(() => {
        if (toast.text !== "") {
            setVisible(true);
        }
    }, [toast])

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if (visible) {
            interval = setInterval(() => {
                setVisible(false);
            }, 3000);
        }
    
        return () => {
            if (interval) {
                clearInterval(interval);
                setVisible(false);
            }
        };
    }, [visible]);

    return (
        <div>
            <Toast 
                show={visible}
                style={{position: "fixed", bottom: "10px", right: "10px"}} 
                bg={toast.isPositive ? "success" : "danger"}
            >
                <Toast.Body className={"text-white"} style={{fontSize: "16px"}}>
                    {toast.isPositive && <i className="bi bi-check-circle-fill"> </i>}
                    {!toast.isPositive && <i className="bi bi-exclamation-triangle-fill"> </i>}
                    &nbsp;{toast.text}
                </Toast.Body>
            </Toast>
        </div>
    )
}

export default NotificationToast;

