import React, { useEffect, useState } from "react";
import { Form, Button, FloatingLabel } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./LogIn.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setIsLoggedIn } from "../../../redux/actions/clientActions";
import { Api } from "../../../services/Api";
import { useTranslation } from "react-i18next";
import getUiLanguage from "../../../utils/getUiLanguage/getUiLanguage";
import { setToast } from "../../../redux/actions/appActions";
import { useNavigate } from "react-router-dom";

interface ILogin {
  signUpSelectedTrigger(): void;
  continue: string | null;
}

const LogIn: React.FC<ILogin> = (props) => {
  const { t, i18n } = useTranslation();
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const isMobile = useSelector((state: RootState) => state.client.isMobile);
  const [usernameEmailError, setUsernameEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogIn = (event: React.FormEvent) => {
    event.preventDefault();

    const bodyJson = {
      UsernameOrEmail: usernameOrEmail,
      Password: password,
    };

    fetch("api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyJson),
    }).then(
      (res) => {
        if (res.ok) {
          dispatch(setIsLoggedIn(true));
          i18n.changeLanguage(getUiLanguage());
          dispatch(
            setToast({ text: "User logged in correctly", isPositive: true })
          );
          if (props.continue) {
            window.location.href = props.continue;
          } else {
            navigate("/");
          }
        } else {
          res.json().then((result) => {
            dispatch(setToast({ text: result.message, isPositive: false }));
            setPasswordError(result.message);
            setUsernameEmailError(result.message);
          });
        }
      },
      (error) => {
        dispatch(setToast({ text: error.message, isPositive: false }));
      }
    );
  };

  return (
    <div className="container pt-3">
      <Row>
        <div className={isMobile ? styles.centerMobile : styles.centerDesktop}>
          <h2 className="text-center">{t("components.feature.logIn.title")}</h2>
          <Row>
            <Form.Group controlId="formUsernameEmail" className="my-3">
              <FloatingLabel
                controlId="floatingUsernameOrEmail"
                label={t("components.feature.logIn.usernameOrEmail")}
              >
                <Form.Control
                  type="text"
                  placeholder={t("components.feature.logIn.usernameOrEmail")}
                  value={usernameOrEmail}
                  onChange={(e) => setUsernameOrEmail(e.target.value)}
                  isInvalid={!!usernameEmailError}
                  style={{ borderColor: usernameEmailError ? "red" : "" }}
                />
              </FloatingLabel>
              <Form.Text style={{ color: "red" }}>
                {usernameEmailError}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formPassword" className="my-3">
              <FloatingLabel
                controlId="floatingPassword"
                label={t("components.feature.logIn.password")}
              >
                <Form.Control
                  type="password"
                  placeholder={t("components.feature.logIn.password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={!!passwordError}
                  style={{ borderColor: passwordError ? "red" : "" }}
                />
              </FloatingLabel>
              <Form.Text style={{ color: "red" }}>{passwordError}</Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <div className="my-3">
                <Button
                  variant="dark"
                  className="w-100"
                  type="button"
                  onClick={handleLogIn}
                >
                  {t("components.feature.logIn.button")}{" "}
                  <i className="bi bi-arrow-right"></i>
                </Button>
                <Button
                  variant="light"
                  onClick={() => props.signUpSelectedTrigger()}
                  className="w-100 mt-2"
                >
                  <i className="bi bi-arrow-left"></i>{" "}
                  {t("components.feature.signUp.button")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default LogIn;
