import WelcomePage from "./containers/WelcomePage/WelcomePage";
import AccountsPage from "./containers/AccountsPage/AccountsPage";
import InfoPage from "./containers/InfoPage/InfoPage";
import SettingsPage from "./containers/SettingsPage/SettingsPage";
import { Outlet } from "react-router-dom";
import VerifyEmailPage from "./containers/VerifyEmailPage/VerifyEmailPage";
import { element } from "./components/feature/NavMenu/elements/AdditionalOptionsMenu/AdditionalOptionsMenu.module.css";
import SignUpSuccess from "./containers/SignUpSuccess/SignUpSuccess";

/**
 * Implement routing schema to the application.
 * @property {boolean} index - Set true for the index route.
 * @property {boolean} isNavMenuHidden - If set as true will hide NavMenu for this route. Default value = false.
 * @property {elemnt} element - Parent component which will be generated for specific route.
 */

const AppRoutes = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "/accounts",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <AccountsPage />,
      },
      {
        path: "verify-email",
        element: <VerifyEmailPage />,
      },
      {
        path: "sign-up-success",
        element: <SignUpSuccess />,
      },
    ],
  },
  {
    path: "/info",
    element: <InfoPage />,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },
];

export default AppRoutes;
